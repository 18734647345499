import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
    title: string;
    description: string;
}

export const SEO: React.SFC<SEOProps> = ({ title, description }) => (
    <Helmet
        title={title}
        meta={[
            {
                name: "description",
                content: description
            }
        ]}
    />
);
