import { FooterContent } from '../../types/footer';

export const footerData: FooterContent = {
    summary:
        "Jeśli są Państwo zainteresowani współpracą, lub też mają jakiekolwiek pytania, zapraszamy do skontaktowania się z odpowiednim oddziałem firmy.",
    company: {
        name: "Sukces Plus",
        address: "ul. Okrężna 1, 66-500 Strzelce Kraj.",
        taxId: "NIP: 5992144330"
    }
};
