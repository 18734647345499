export const design = {
    white: "#FFFFFF",
    background: "#FCFCFA",
    shade: "#F7F7F5",
    footer: "#F0EFED",
    sukces: "#FFC800",
    sukcesReadable: "#FABB00",
    bike: "#20A2EF",
    text: "#292929",
    grey: "#666666",
    separator: "#B8B7B6",
    fontFamilyDefault:
        '"Source Sans Pro", Helvetica, Segoe, "Segoe UI", sans-serif',
    fontFamilySerif: "Georgia, serif",
    maxMobileWidth: "960px"
};
