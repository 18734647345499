import * as React from 'react';
import styled from 'styled-components';
import { Brand } from '../../types/brand';
import { design } from '../styles/variables';
import { Wrapper } from './Wrapper';

interface BannerProps {
    companyName: string;
    logo: string;
    background: string;
    image: string;
    imageBorder?: string;
    text: {
        headline?: {
            strong?: string;
            regular?: string;
            color?: Brand;
        };
        subline?: string;
    };
}

const Container = styled.div`
    position: relative;
    width: 100%;
    background: #fcfcfc;
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 12px;
    z-index: 2;

    & > div {
        padding: 0 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
    }
`;

const Image = styled.div`
    position: relative;
    width: 100%;
    height: 640px;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: ${design.maxMobileWidth}) {
        display: none;
    }
`;

const ImageMobile = styled.img`
    display: none;
    width: 100%;
    height: auto;

    @media (max-width: ${design.maxMobileWidth}) {
        display: block;
    }
`;

const ImageBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: ${design.maxMobileWidth}) {
        display: none;
    }
`;

interface HeadlineStyleProps {
    color: Brand;
}

const Headline = styled.div`
    position: absolute;
    top: 122px;
    width: 440px;
    left: 50%;
    margin: 60px;
    margin-left: -420px;
    z-index: 3;
    text-align: left;

    & h1 {
        line-height: 1;

        & strong {
            display: block;
            font-size: 3.6rem;
            font-weight: 400;
            color: ${(s: HeadlineStyleProps) =>
                s.color === "bike" ? design.bike : design.sukcesReadable};
        }

        & span {
            display: block;
            font-size: 2.4rem;
            font-weight: 400;
            color: ${design.grey};
        }
    }

    & h3 {
        margin-top: 32px;
        max-width: 280px;
        line-height: 1.3;
        font-size: 1.25rem;
        font-weight: 400;
        font-style: italic;
        font-family: ${design.fontFamilySerif};
    }

    @media (max-width: ${design.maxMobileWidth}) {
        background: ${design.shade};
        position: relative;
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 32px;
        padding-top: 92px;
        z-index: 1;
    }
`;

const defaults: Partial<BannerProps> = {
    text: {
        headline: {
            strong: "",
            regular: "",
            color: "sukces"
        },
        subline: ""
    }
};

export const Banner: React.SFC<BannerProps> = ({
    companyName,
    logo,
    background,
    image,
    imageBorder,
    text: { headline, subline }
}) => (
    <Container style={{ background }}>
        <Header>
            <Wrapper>
                <img src={logo} alt={companyName} />
                <a href="#kontakt">Lokalizacja i kontakt</a>
            </Wrapper>
        </Header>

        <Headline color={headline.color || "sukces"}>
            <h1>
                {headline.strong && <strong>{headline.strong}</strong>}

                {headline.regular && <span>{headline.regular}</span>}
            </h1>

            {subline && <h3>{subline}</h3>}
        </Headline>

        <Image style={{ backgroundImage: "url(" + image + ")" }}>
            <div />

            {imageBorder && (
                <ImageBorder
                    style={{ backgroundImage: "url(" + imageBorder + ")" }}
                />
            )}
        </Image>

        <ImageMobile src={image} alt="" />
    </Container>
);

Banner.defaultProps = defaults;
