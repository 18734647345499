import { Wrapper } from '.';
import * as React from 'react';
import styled from 'styled-components';
import { Brand } from '../../types/brand';
import { ContactInfo } from '../../types/contact';
import { footerData } from '../data/footer';
import { design } from '../styles/variables';

const facebookIcon = require("../images/social-facebook.png");
const googleMapsIcon = require("../images/social-google-maps.png");
const bikeSukcesIcon = require("../images/other-site-bike.png");
const mebleSukcesIcon = require("../images/other-site-meble.png");

interface FooterProps {
    contact: ContactInfo;
    otherLink: Brand;
}

interface FooterState {
    isReady: boolean;
}

const Container = styled.div`
    background: ${design.footer};
`;

const Contact = styled.div`
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    gap: 64px;
    padding: 64px 0;
    text-align: left;
    font-size: 1rem;

    & > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
    }

    @media (max-width: ${design.maxMobileWidth}) {
        padding: 32px 0;
        grid-template: auto / 100%;
        gap: 32px;

        & > * {
            display: block;

            &:nth-child(1) {
                grid-row: 3;
            }

            &:nth-child(2) {
                grid-row: 2;
            }

            &:nth-child(3) {
                grid-row: 1;
            }
        }
    }
`;

const Invitation = styled.div`
    flex: 0 1 auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;

    & p {
        max-width: 220px;
        margin-left: 0;
        margin-right: auto;
        line-height: inherit;
    }
`;

const ContactList = styled.ul`
    & li {
        margin-top: 8px;

        &.gap {
            margin-top: 16px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
`;

const SocialList = styled.ul`
    font-size: 0.9rem;
    width: 230px;

    & li {
        width: 100%;
        margin-top: 12px;

        & a {
            background: ${design.background};
            display: flex;
            align-content: center;
            align-items: center;
            border-bottom: none;
            border-radius: 4px;
            border: 1px solid ${design.background};
            padding-right: 12px;
            overflow: hidden;

            &:hover {
                border: 1px solid ${design.bike};
            }

            &:focus {
                border-color: ${design.bike};
                background: ${design.bike};
            }
        }

        & img {
            width: 48px;
            height: 48px;
            border-radius: 4px;
            margin-right: 12px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 16px 0;
    font-size: 0.8rem;
    border-top: 1px solid ${design.separator};

    & > div {
        text-align: left;
        padding: 0 8px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            text-align: right;
        }
    }

    @media (max-width: ${design.maxMobileWidth}) {
        flex-direction: column;

        & > div {
            text-align: center;

            &:last-child {
                margin-top: 24px;
            }
        }
    }
`;

export class Footer extends React.Component<FooterProps, FooterState> {
    state: FooterState = {
        isReady: false
    };

    public componentDidMount() {
        this.setState({
            isReady: true
        });
    }

    public render() {
        const {
            contact: { email, phone, address, openingHours, links },
            otherLink
        } = this.props;

        return (
            <Container>
                <Wrapper>
                    <Contact id="kontakt">
                        <SocialList>
                            {links.googleMaps && (
                                <li>
                                    <a
                                        href={links.googleMaps.url}
                                        target="_blank"
                                        rel="nofollow noreferrer"
                                    >
                                        <img
                                            src={googleMapsIcon}
                                            alt="Google Maps"
                                        />
                                        <strong>
                                            {links.googleMaps.label}
                                        </strong>
                                    </a>
                                </li>
                            )}

                            {links.facebook && (
                                <li>
                                    <a
                                        href={links.facebook.url}
                                        target="_blank"
                                        rel="nofollow noreferrer"
                                    >
                                        <img
                                            src={facebookIcon}
                                            alt="Facebook Fanpage"
                                        />
                                        <strong>{links.facebook.label}</strong>
                                    </a>
                                </li>
                            )}

                            {(otherLink === "bike" && (
                                <li>
                                    <a href="https://bikesukces.pl">
                                        <img src={bikeSukcesIcon} alt="" />
                                        <strong>Nasz sklep rowerowy</strong>
                                    </a>
                                </li>
                            )) || (
                                <li>
                                    <a href="https://meblesukces.pl">
                                        <img src={mebleSukcesIcon} alt="" />
                                        <strong>Nasz sklep meblowy</strong>
                                    </a>
                                </li>
                            )}
                        </SocialList>

                        <ContactList>
                            {email && this.state.isReady && (
                                <li>
                                    <a href={"mailto:" + email}>{email}</a>
                                </li>
                            )}

                            {phone && (
                                <li>
                                    {"Tel. "}
                                    <strong>{phone}</strong>
                                </li>
                            )}

                            <li className="gap">
                                {"ul. "}
                                <strong>{address.street}</strong>
                            </li>

                            <li>
                                {address.postalCode
                                    ? address.postalCode + " "
                                    : false}
                                <strong>{address.city}</strong>
                            </li>

                            {openingHours.map((hours, hoursIndex) => (
                                <li
                                    key={hoursIndex}
                                    className={
                                        hoursIndex <= 0 ? "gap" : undefined
                                    }
                                >
                                    {hours.period + " "}
                                    <strong>
                                        {hours.from + " – " + hours.to}
                                    </strong>
                                </li>
                            ))}
                        </ContactList>

                        <Invitation>
                            <p>{footerData.summary}</p>
                        </Invitation>
                    </Contact>

                    <Bottom>
                        <div>
                            <p>
                                {footerData.company.name +
                                    ", " +
                                    footerData.company.address +
                                    ", " +
                                    footerData.company.taxId}
                            </p>
                        </div>

                        <div>
                            <p>
                                {"Strona wykonana przez "}
                                <a
                                    href="https://vidua.pl"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                >
                                    VIDUA
                                </a>
                            </p>
                        </div>
                    </Bottom>
                </Wrapper>
            </Container>
        );
    }
}
