import { timingFunctions, transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { design } from './variables';

export const GlobalStyles = createGlobalStyle`
    html {
        margin: 0;
        padding: 0;
        border: 0;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: ${design.fontFamilyDefault};
        line-height: 1;
        text-align: center;
        background: ${design.background};
        color: ${design.text};
        scroll-behavior: smooth;
    }

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
        line-height: 1.3;
    }

    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        cursor: pointer;
        outline: none;
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid ${design.separator};
        transition: 300ms border 0ms ${timingFunctions("easeInOutExpo")},
            300ms background 0ms ${timingFunctions("easeInOutCubic")},
            300ms color 0ms ${timingFunctions("easeInOutCubic")};

        &:hover {
            border-bottom: 1px solid ${design.bike};
        }

        &:focus {
            background: ${design.bike};
            color: ${design.white};
        }
    }

    ::selection {
        background: ${design.bike};
        color: ${design.white};
    }
`;
