import * as React from 'react';
import { GlobalStyles } from '../styles/global';
import { SEO } from './SEO';

interface LayoutProps {
    pageTitle: string;
    pageDescription: string;
}

export const Layout: React.SFC<LayoutProps> = ({
    pageTitle,
    pageDescription,
    children
}) => (
    <React.Fragment>
        <SEO title={pageTitle} description={pageDescription} />
        <GlobalStyles />
        <div>{children}</div>
    </React.Fragment>
);
