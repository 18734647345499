import * as React from 'react';
import styled from 'styled-components';

interface WrapperProps {
    className?: string;
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 1020px;
    padding: 0 30px;
    box-sizing: border-box;
`;

export const Wrapper: React.SFC<WrapperProps> = ({ className, children }) => (
    <Container className={className}>{children}</Container>
);
