import * as React from 'react';
import styled from 'styled-components';

interface BoxProps {
    className?: string;
    justifyContent:
        | "flex-start"
        | "flex-end"
        | "center"
        | "space-around"
        | "space-between";
    alignContent: "flex-start" | "flex-end" | "center" | "stretch";
    alignItems: "flex-start" | "flex-end" | "center" | "stretch";
    flexWrap: "nowrap" | "wrap" | "wrap-reverse";
}

const defaults: BoxProps = {
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "nowrap"
};

const Flex = styled.div`
    display: flex;
    flex-wrap: ${(p: BoxProps) => p.flexWrap};
    justify-content: ${(p: BoxProps) => p.justifyContent};
    align-content: ${(p: BoxProps) => p.alignContent};
    align-items: ${(p: BoxProps) => p.alignItems};
`;

export const Box: React.SFC<Partial<BoxProps>> = ({
    children,
    className,
    ...props
}) => (
    <Flex className={className || undefined} {...props}>
        {children}
    </Flex>
);

Box.defaultProps = defaults;
