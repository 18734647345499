import { Link } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Brand } from '../../types/brand';
import { GlobalStyles } from '../styles/global';
import { design } from '../styles/variables';

interface NotFoundContentProps {
    color: Brand;
}

interface ContainerProps {
    brand: Brand;
}

const Container = styled.div`
    width: 100%;
    max-width: 640px;
    margin: 128px auto;
    padding: 128px;
    text-align: center;
    box-sizing: border-box;
    border: 6px solid
        ${(s: ContainerProps) =>
            s.brand === "bike" ? design.bike : design.sukcesReadable};

    @media (max-width: ${design.maxMobileWidth}) {
        max-width: ${design.maxMobileWidth};
        margin: 0 auto;
        padding: 64px;
    }
`;

interface HeadlineProps {
    brand: Brand;
}

const Headline = styled.h1`
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.1;
    color: ${(s: HeadlineProps) =>
        s.brand === "bike" ? design.bike : design.sukcesReadable};
`;

const BackLink = styled(Link)`
    display: inline-block;
    margin-top: 24px;
    font-size: 1.3rem;
    font-weight: 400;
`;

export const NotFoundContent: React.SFC<NotFoundContentProps> = ({ color }) => (
    <Container brand={color}>
        <GlobalStyles />
        <Helmet title="Strona nie istnieje" />
        <Headline brand={color}>404 strona nie istnieje</Headline>
        <BackLink to="/">Wróć do strony głównej</BackLink>
    </Container>
);
