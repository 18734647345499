import * as React from 'react';
import styled from 'styled-components';
import { Brand } from '../../types/brand';
import { design } from '../styles/variables';
import { Wrapper } from './Wrapper';

interface ContentProps {
    short: string;
    long: string[];
    color: Brand;
}

interface ContainerProps {
    color: Brand;
}

const Container = styled.section`
    padding: 64px 0;
    text-align: left;
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    gap: 16px;

    & h2 {
        padding: 48px 32px;
        font-size: 1.5rem;
        line-height: 1.3;
        font-weight: 700;
        color: ${(s: ContainerProps) =>
            s.color === "bike" ? design.bike : design.sukcesReadable};
        border: 6px solid
            ${(s: ContainerProps) =>
                s.color === "bike" ? design.bike : design.sukces};
    }

    & div {
        grid-column: 2 / span 2;
        background: ${design.shade};
        padding: 48px;

        & p {
            font-family: ${design.fontFamilySerif};
            font-size: 1.15rem;
            margin-top: 24px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media (max-width: ${design.maxMobileWidth}) {
        padding: 32px 0;
        grid-template: auto / 100%;

        & div {
            grid-column: 1;
            padding: 32px;
        }
    }
`;

export const Content: React.SFC<ContentProps> = ({ short, long, color }) => (
    <Wrapper>
        <Container color={color}>
            <h2>{short}</h2>
            <div>
                {long.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </Container>
    </Wrapper>
);
