import { stripUnit } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Brand } from '../../types/brand';
import { design } from '../styles/variables';
import { Wrapper } from './Wrapper';

interface GalleryProps {
    images: string[];
    mobileImages: string[];
}

const Container = styled(Wrapper)`
    padding-bottom: 64px;
`;

const Grid = styled.div`
    display: none;

    & > div {
        overflow: hidden;
        position: relative;
        background: ${design.shade};
        box-sizing: border-box;
        width: auto;
        height: auto;

        & img {
            width: 100%;
            height: auto;
        }
    }
`;

const GridDesktop = styled(Grid)`
    @media (min-width: ${(stripUnit(design.maxMobileWidth) as number) +
            1 +
            "px"}) {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr 1fr;

        & > div {
            &:nth-child(1) {
                grid-column: 1;
                grid-row: 1;
            }

            &:nth-child(2) {
                grid-column: 2;
                grid-row: 1;
            }

            &:nth-child(3) {
                grid-column: 3;
                grid-row: 1 / span 2;

                & img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: auto;
                    height: 100%;
                    transform: translateX(-50%);
                }
            }

            &:nth-child(4) {
                grid-column: 1 / span 2;
                grid-row: 2 / span 2;
            }

            &:nth-child(5) {
                grid-column: 3;
                grid-row: 3;
            }

            &:nth-child(6) {
                grid-column: 1;
                grid-row: 4;
            }

            &:nth-child(7) {
                grid-column: 2 / span 2;
                grid-row: 4;

                & img {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: auto;
                    transform: translateY(-50%);
                }
            }
        }
    }
`;

const GridMobile = styled(Grid)`
    display: none;

    & div {
        display: block;
        margin-top: 4px;

        & img {
            display: block;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    @media (max-width: ${design.maxMobileWidth}) {
        display: block;
    }
`;

export const Gallery: React.SFC<GalleryProps> = ({ images, mobileImages }) => (
    <Container>
        <GridDesktop>
            {images.map(image => (
                <div key={image}>
                    <img src={image} alt="" />
                </div>
            ))}
        </GridDesktop>

        <GridMobile>
            {mobileImages.map(image => (
                <div key={image}>
                    <img src={image} alt="" />
                </div>
            ))}
        </GridMobile>
    </Container>
);
